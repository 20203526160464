import React from 'react';
import closePointer from '../../assets/images/closePointer.svg';
import './Modal.css';

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  renderElement: any;
  packagesIndex: number;
}

const Modal: React.FC<ModalProps> = ({ isVisible, onClose, renderElement, packagesIndex }) => {
  // const [images] = useState(() => [packagesModal1, packagesModal2, packagesModal3])

  return (
    <>
      {isVisible && (
        <div className="modal">
          <div className="modal-section">
            <div className="modal-content">
              {/* <img src={images[packagesIndex - 1]} alt="" /> */}
              {renderElement}
            </div>
            <button className="modal-close-button" onClick={onClose}>
              <img className="modalCloseIcon" src={closePointer} alt="" />Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;