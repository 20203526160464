import { useEffect, useState } from 'react';
import './Photos.css'
import texts from '../../assets/en.json';
import image1 from '../../assets/images/pagePhotos/image1.png'
import image2 from '../../assets/images/pagePhotos/image2.png'
import image3 from '../../assets/images/pagePhotos/image3.png'
import image4 from '../../assets/images/pagePhotos/image4.png'
import image5 from '../../assets/images/pagePhotos/image5.png'
import image6 from '../../assets/images/pagePhotos/image6.png'
import image7 from '../../assets/images/pagePhotos/image7.png'
import image8 from '../../assets/images/pagePhotos/image8.png'
import image9 from '../../assets/images/pagePhotos/image9.png'
import image10 from '../../assets/images/pagePhotos/image10.png'
import { useTransition, animated } from 'react-spring';

interface IPhotos {
  currentKey: string
}

export const Photos = ({ currentKey }: IPhotos) => {
  const header: any = texts.header;
  const [isVisible, setIsVisible] = useState(false)

  const transaction = useTransition(isVisible, {
    from: { transform: 'translateY(200%)' },
    enter: { transform: 'translateY(-50%)' },
    leave: {
      transform: `translateY(${currentKey !== header.home.toLowerCase() &&
          currentKey !== header['party packages'].toLowerCase() &&
          currentKey !== header.extras.toLowerCase() ? -200 : 200}%)`
    },
    config: { duration: 500 }
  })

  useEffect(() => {
    if (currentKey !== header.photos.toLowerCase()) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [currentKey])

  return (<>
    {transaction((style, item) => item ? (
      <animated.div style={style} className='container item' id='photos'>
        <figure className='item1'><img className='image1' src={image1} alt="image1" /></figure>
        <figure className='item2'><img className='image2' src={image2} alt="image2" /></figure>
        <figure className='item3'><img className='image3' src={image3} alt="image3" /></figure>
        <figure className='item4'><img className='image4' src={image4} alt="image4" /></figure>
        <figure className='item5'><img className='image5' src={image5} alt="image5" /></figure>
        <figure className='item6'><img className='image6' src={image6} alt="image6" /></figure>
        <figure className='item7'><img className='image7' src={image4} alt="image7" /></figure>
        <figure className='item8'><img className='image8' src={image5} alt="image8" /></figure>
        <figure className='item9'><img className='image9' src={image7} alt="image9" /></figure>
        <figure className='item10'><img className='image10' src={image8} alt="image10" /></figure>
        <figure className='item11'><img className='image11' src={image9} alt="image11" /></figure>
        <figure className='item12'><img className='image12' src={image10} alt="image12" /></figure>
      </animated.div>
    ) : '')}
  </>
    // <div className='container' id='photos'>

    // </div>
  );
}