import './LeftBar.css'
import yell from '../../assets/images/yell.png'
import instagram from '../../assets/images/instagram.png'
import { useEffect, useState } from 'react';
import { useTransition, animated } from 'react-spring';

interface ILeftBar {
  currentKey: string
  contactUsKey: string
}
export const LeftBar = ({ currentKey, contactUsKey }: ILeftBar) => {
  const [isVisible, setIsVisible] = useState(true)

  const transaction = useTransition(isVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500, easing: t => t * t }
  })

  useEffect(() => {
    if (currentKey.toLowerCase() === contactUsKey) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [currentKey])

  return (<>
    {transaction((style, item) => item ? (
      <animated.div style={{ ...style }} className='container item' id='left-bar'>
        <div id='yell'>
          <a target="_blank" href="https://yelp.to/OAVYJ06Jed"><img src={yell} alt="" /></a>
        </div>
        <div id='instagram'>
          <a target="_blank" href="https://instagram.com/kidztopia_indoorplayground?igshid=OGQ5ZDc2ODk2ZA=="><img src={instagram} alt="" /></a>
        </div>
      </animated.div>
    ) : '')}
  </>)
}