import { useEffect, useState } from 'react';
import texts from '../../assets/en.json';
import { useTransition, animated } from 'react-spring';

import './Packages.css'

interface IPackages {
    currentKey: string
    previousKey: string
}

export const Packages = ({ currentKey, _ }: IPackages) => {
    const [isVisible, setIsVisible] = useState(false);
    const header: any = texts.header;

    const transaction = useTransition(isVisible, {
        from: { transform: 'translateY(100%)' },
        enter: { transform: 'translateY(-45%)' },
        leave: { transform: `translateY(${currentKey !== header.home.toLowerCase() ? -100 : 100}%)` },
        config: { duration: 500, easing: t => t * t }
    })

    useEffect(() => {
        if (currentKey !== header['party packages'].toLowerCase()) {
            setIsVisible(false)
        } else {
            setIsVisible(true)
        }
    }, [currentKey, header['party packages']])

    return (<>
        {transaction((style, item) => item ? (
            <animated.div style={style} className='container item' id='packages'>
                <div className='packages__wrapper'>

                    <div className='packages__item package'>
                        <h2 className='package__heading'>Party Package One</h2>
                        <p className='package__price'>$1700</p>
                        <p className='package__tax'>( Additional 15% service fee 9.5% tax )</p>

                        <div className='package__infos'>
                            <div className='package__info'>
                                <p className='package__infoname'>Party Time</p>
                                <b className='package__boldinfo'>2 Hours</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Kids</p>

                                <div>
                                    <span className='package__addinfo'>(each additional kid $16)</span>
                                    <b className='package__boldinfo'>up to 22</b>
                                </div>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Adults</p>

                                <div>
                                    <span className='package__addinfo'>(additional adult $12) </span>
                                    <b className='package__boldinfo'>up to 32</b>
                                </div>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Helpers</p>
                                <b className='package__boldinfo'>2-3</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Large 1 topping pizza</p>
                                <b className='package__boldinfo'>4</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Organic Juice boxes kids</p>
                                <b className='package__boldinfo'>22</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Mini Water bottles</p>
                                <b className='package__boldinfo'>provided for all guests</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Non slip socks</p>
                                <b className='package__boldinfo'>provided for all guests</b>
                            </div>
                            <p className='package__secondinfo'>American coffee with creamer and sugar</p>
                            <p className='package__secondinfo'>Plates, napkins, flatwear for adults </p>
                            <p className='package__secondinfo'>Cake celebration, cutting ,serving cake</p>
                            <p className='package__secondinfo last'>Cake stands included (options of clear, white, variety of colors/ designs to chose from) </p>
                        </div>

                        <div className='package__additional'>
                            <div className='package__additional_info'>
                                <p>Additional 30 minutes</p>
                                <b>$250</b>
                            </div>
                            <div className='package__additional_info'>
                                <p>Additional 1 hour</p>
                                <b>$350</b>
                            </div>
                        </div>
                    </div>
                    <div className='packages__item package gold'>
                        <h2 className='package__heading'>Party Package Two</h2>
                        <p className='package__price'>$2200</p>
                        <p className='package__tax'>Plus additional 15% service fee 9.5% tax </p>

                        <div className='package__infos'>
                            <div className='package__info'>
                                <p className='package__infoname'>Party Time</p>
                                <b className='package__boldinfo'>2.5 Hours</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Kids</p>

                                <div>
                                    <span className='package__addinfo'>(each additional kid $16) </span>
                                    <b className='package__boldinfo'>up to 27</b>
                                </div>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Adults</p>

                                <div>
                                    <span className='package__addinfo'>(additional adult $12) </span>
                                    <b className='package__boldinfo'>up to 37</b>
                                </div>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Helpers</p>
                                <b className='package__boldinfo'>2-3</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Large 1 topping pizza</p>
                                <b className='package__boldinfo'>6</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Organic Juice boxes kids</p>
                                <b className='package__boldinfo'>27</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Mini Water bottles</p>
                                <b className='package__boldinfo'>provided for all guests</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Non slip socks</p>
                                <b className='package__boldinfo'>provided for all guests</b>
                            </div>
                            <p className='package__secondinfo'>American coffee with creamer and sugar</p>
                            <p className='package__secondinfo'>Hot tea 9 different flavor varieties</p>
                            <p className='package__secondinfo'>Plates, cups, napkins, flatwear for adults </p>
                            <p className='package__secondinfo'>Cake celebration ,cutting ,serving cake plates / forks included </p>
                            <p className='package__secondinfo'>Themed plates, napkins centerpieces for kids table</p>
                            
                            <p className='package__secondinfo last'>Cake stands included (options of clear, white, variety of colors/ designs to chose from) </p>
                        </div>

                        <div className='package__additional'>
                            <div className='package__additional_info'>
                                <p>Additional 30 minutes</p>
                                <b>$250</b>
                            </div>
                            <div className='package__additional_info'>
                                <p>Additional 1 hour</p>
                                <b>$350</b>
                            </div>
                        </div>
                    </div>
                    <div className='packages__item package'>
                        <h2 className='package__heading'>Party Package Three</h2>
                        <p className='package__price'>$2800</p>
                        <p className='package__tax'>( Additional 15% service fee 9.5% tax )</p>

                        <div className='package__infos'>
                            <div className='package__info'>
                                <p className='package__infoname'>Party Time</p>
                                <b className='package__boldinfo'>3 Hours</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Kids</p>

                                <div>
                                    <span className='package__addinfo'>(each additional kid $16) </span>
                                    <b className='package__boldinfo'>up to 30</b>
                                </div>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Adults</p>

                                <div>
                                    <span className='package__addinfo'>(additional adult $12) </span>
                                    <b className='package__boldinfo'>up to 40</b>
                                </div>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Helpers</p>
                                <b className='package__boldinfo'>2-3</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Large 1 topping pizza</p>
                                <b className='package__boldinfo'>8</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Organic Juice boxes kids</p>
                                <b className='package__boldinfo'>unlimited</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Sodas</p>
                                <b className='package__boldinfo'>provided for all guests</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Mini Water bottles</p>
                                <b className='package__boldinfo'>provided for all guests</b>
                            </div>
                            <div className='package__info'>
                                <p className='package__infoname'>Non slip socks</p>
                                <b className='package__boldinfo'>provided for all guests</b>
                            </div>
                            <p className='package__secondinfo'>Choice of American coffee with creamer or Armenian coffee with side of sugar </p>
                            <p className='package__secondinfo'>Hot tea 9 different flavor for adults </p>
                            <p className='package__secondinfo'>Plates, napkins, flatwear for adults </p>
                            <p className='package__secondinfo'>Themed plates ,napkins, centerpieces for kids table <br /> Goodie bags for all kids  </p>
                            <p className='package__secondinfo'>Cake stands included (options of clear, white, variety of colors/ designs to chose from)  </p>
                            <p className='package__secondinfo'>Goodie bag display stand included </p>
                            <p className='package__secondinfo last'>Character for your themed party (1 hour)</p>
                        </div>

                        <div className='package__additional'>
                            <div className='package__additional_info'>
                                <p>Additional 30 minutes</p>
                                <b>$250</b>
                            </div>
                            <div className='package__additional_info'>
                                <p>Additional 1 hour</p>
                                <b>$350</b>
                            </div>
                        </div>
                    </div>
                </div>
            </animated.div>
        ) : '')}
    </>)
}