import React, { useEffect, useState } from 'react';
import texts from '../../assets/en.json';
import './Header.css';
import Pencil from '../../assets/images/pencil.svg'
import Planner from '../../assets/images/planner.svg'

interface IHeader {
  setCurrentKey: (key: string) => void;
  currentKey: string
}

export const Header = ({ setCurrentKey, currentKey }: IHeader) => {
  const header: any = texts.header;
  const contactUs: any = texts.contactUs;

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [right, setRight] = useState(() => windowSize.width < 1700 ? 100 : 90)
  const [bottom, setBottom] = useState(() => windowSize.width < 1600 ? 70 : 105)

  const handleNavigation = (key: string) => {
    setCurrentKey(key);
  };


  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      if (window.innerWidth < 1600) {
        setRight(100)
        setBottom(70)
      } else {
        setRight(90)
        setBottom(105)
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='container' id='header'>
      <div className="radio-input">
        {Object.keys(header).map((listKey, index) => (
          <label
            key={`value-` + index + 1}
            onClick={() => handleNavigation(listKey)}
          >
            <input
              value={`value-` + index + 1}
              name="value-radio"
              id={`value-` + index + 1}
              type="radio"
              checked={listKey === currentKey}
              defaultChecked={index === 0}
            />
            <span>{header[listKey]}</span>
          </label>
        ))}
        <span className="selection"></span>
      </div>
      <div className='header-buttons'>
        <a id='sign' target='_blank'
          href="https://kidztopiaplayground.aluvii.com/employee/Waiver/SignWaiver2?waiverId=1">
          Sign  Waiver
        </a>
        {/* <a id='ticket' target='_blank'
          href={`tel:${contactUs.phone}`}>
          Purchase Tickets
        </a> */}
        <a id='booking' target='_blank'
          href={`tel:${contactUs.phone}`}>
          Call For Party Booking
        </a>
      </div>
    </div>
  );
}