import { useEffect, useState } from 'react';
import './RightBar.css'
import texts from '../../assets/en.json';
import arrowBottomWithTransparency from '../../assets/images/arrowBottomWithTransparency.svg'
import arrowBottom from '../../assets/images/arrowBottom.svg'
import arrowTopWithTransparency from '../../assets/images/arrowTopWithTransparency.svg'
import arrowTop from '../../assets/images/arrowTop.svg'

interface IRightBar {
  setCurrentKey: (key: string) => void
  currentKey: string
}

export const RightBar = ({ setCurrentKey, currentKey }: IRightBar) => {
  const header: any = texts.header;
  const headerLength = Object.keys(header).length
  let [current, setCurrent] = useState(0);

  useEffect(() => {
    setCurrent(Object.keys(header).findIndex(item => currentKey === item))
  }, [currentKey])

  const next = () => {
    if (current < headerLength - 1) {
      setCurrent(++current);
      setCurrentKey(Object.keys(header)[current]);
    }
  }

  const prev = () => {
    if (current > 0) {
      setCurrent(--current);
      setCurrentKey(Object.keys(header)[current]);
    }
  }

  return (
    <div className='container' id='right-bar'>
      {current === 0 ?
        (<div id='arrowTopWithTransparency'>
          <img src={arrowTopWithTransparency} alt="" />
        </div>) :
        (<div id='arrowTop' onClick={prev}>
          <img src={arrowTop} alt="" />
        </div>)}

      {current === headerLength - 1 ?
        (<div id='arrowBottomWithTransparency'>
          <img src={arrowBottomWithTransparency} alt="" />
        </div>) :
        (<div id='arrowBottom' onClick={next}>
          <img src={arrowBottom} alt="" />
        </div>)}
    </div>
  );
}