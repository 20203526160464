import { useEffect, useState } from 'react';
import './Photos.css'
import texts from '../../../assets/en.json';
import left from '../../../assets/images/left.svg'
import right from '../../../assets/images/right.svg'
import image1 from '../../../assets/images/pagePhotos/image1.png'
import image2 from '../../../assets/images/pagePhotos/image1.png'
import image3 from '../../../assets/images/pagePhotos/image1.png'
import image4 from '../../../assets/images/pagePhotos/image1.png'
import image5 from '../../../assets/images/pagePhotos/image1.png'
import image6 from '../../../assets/images/pagePhotos/image1.png'
import image7 from '../../../assets/images/pagePhotos/image1.png'
import image8 from '../../../assets/images/pagePhotos/image1.png'
import image9 from '../../../assets/images/pagePhotos/image1.png'
import image10 from '../../../assets/images/pagePhotos/image1.png'
import { useTransition, animated } from 'react-spring';


interface MobilePhotos {
  currentKey: string
}


const pictures = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
]


export const MobilePhotos = ({ currentKey }: MobilePhotos) => {
  const header: any = texts.header;
  const [currentPicture, setCurrentPicture] = useState(0);
  const [isVisible, setIsVisible] = useState(false)

  const transaction = useTransition(isVisible, {
    from: { transform: 'translateY(100%)', position: 'absolute' },
    enter: { transform: 'translateY(0%)', position: 'relative' },
    leave: { transform: 'translateY(-100%)', position: 'absolute' },
    config: { duration: 500 }
  })

  useEffect(() => {
    if (currentKey !== header.photos.toLowerCase()) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [currentKey])


  const handleNext = () => {
    setCurrentPicture((prevIndex) => (prevIndex + 1) % pictures.length);
  };

  const handlePrev = () => {
    setCurrentPicture(
      (prevIndex) => (prevIndex - 1 + pictures.length) % pictures.length
    );
  };


  return (<>
    {transaction((style, item) => item ? (
      <animated.div style={style} id='mobilePhotos'>
        <div className='imageContainer'>
          <div className='mobileDisplayedImage'>
            <img
              src={pictures[currentPicture]}
              alt={`Picture ${currentPicture + 1}`}
              className='mobilePhotos'
            />
          </div>


          <figure className='mobileImageContainer'>
            <button className='mobileImageListButtons' id='prev' onClick={handlePrev}><img src={left} /></button>
            <div className='mobileIamgeList'>
              {pictures.map((picture, index) => (
                <img
                  key={index}
                  src={picture}
                  alt={`Thumbnail ${index + 1}`}
                  className='mobileImageItem'
                  onClick={() => setCurrentPicture(index)}
                />
              ))}
            </div>
            <button className='mobileImageListButtons' id='next' onClick={handleNext}><img src={right} /></button>
          </figure>

        </div>
      </animated.div>
    ) : '')}
  </>
    // <div className='container' id='photos'>

    // </div>
  );
};