import React, { useEffect, useState } from 'react';
import texts from '../../assets/en.json';
import './Footer.css';
import Pencil from '../../assets/images/pencil.svg'
import Planner from '../../assets/images/planner.svg'

interface MobileFooter {
  setCurrentKey: (key: string) => void;
  currentKey: string
}

export const MobileFooter = ({ setCurrentKey, currentKey }: MobileFooter) => {
  const header: any = texts.header;
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [right, setRight] = useState(() => windowSize.width < 1700 ? 100 : 90)
  const [bottom, setBottom] = useState(() => windowSize.width < 1600 ? 70 : 105)

  const handleNavigation = (key: string) => {
    setCurrentKey(key);
  };


  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      if (window.innerWidth < 1500) {
        setRight(100)
        setBottom(70)
      } else {
        setRight(90)
        setBottom(105)
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='mobileFooter' id='mobileFooter'>

      <div className='button' id='sign' style={
        header.home.toLowerCase() !== currentKey ||
          header.home.toLowerCase() !== currentKey ? {
          right: `${right}%`,
        } : {}
      }>
        <a target='_blank'
          href="https://kidztopiaplayground.aluvii.com/employee/Waiver/SignWaiver2?waiverId=1">
          <img src={Pencil} alt="" />Sign Kidztopia Waiver
        </a>
      </div>

    </div>
  );
}