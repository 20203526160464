import './Header.css';
import texts from '../../assets/en.json';

interface IMobileHeader {
  setCurrentKey: (key: string) => void;
  isContactUs: boolean
}

export const MobileHeader = ({ setCurrentKey, isContactUs }: IMobileHeader) => {
  const contactUs: any = texts.contactUs;
  return (
    <div className='mobileHeader' id='mobileHeader'>
      <div id='mainButtons'>
        {/* <div className='button' id='ticket'>
          <a target='_blank'
            href={`tel:${contactUs.phone}`}>
            Purchase Tickets
          </a>
        </div> */}
        <div className='button' id='mobileBooking'>
          <a target='_blank'
            href={`tel:${contactUs.phone}`}>
            Call for Party Booking
          </a>
        </div>
        <div className='mobileHeader-sub2' style={{ background: isContactUs ? '#FBA15B' : '' }} onClick={() => setCurrentKey('contactUs')}>
          <div className='mobileContactTitle' >Contact Us</div>
        </div>
      </div>
    </div>
  );
}
