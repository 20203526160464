import { useEffect, useState } from 'react';
import texts from '../../assets/en.json';
import './Menu.css';
import Pencil from '../../assets/images/menu/home.svg';
import ActivePencil from '../../assets/images/menu/activeHome.svg';
import Packages from '../../assets/images/menu/packages.svg';
import ActivePackages from '../../assets/images/menu/activePackages.svg';
import Extras from '../../assets/images/menu/extras.svg';
import ActiveExtras from '../../assets/images/menu/activeExtras.svg';
import Photos from '../../assets/images/menu/photos.svg';
import ActivePhotos from '../../assets/images/menu/activePhotos.svg';



interface Menu {
  setCurrentKey: (key: string) => void;
  currentKey: string
}

const menuIcons: any = {
  home: Pencil,
  'party packages': Packages,
  extras: Extras,
  photos: Photos,
}

const menuActiveIcons: any = {
  home: ActivePencil,
  'party packages': ActivePackages,
  extras: ActiveExtras,
  photos: ActivePhotos,
}

export const MobileMenu = ({ setCurrentKey, currentKey }: Menu) => {
  const menu: any = texts.mobileMenu;
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [_, setRight] = useState(() => windowSize.width < 1700 ? 100 : 90)
  const [bottom, setBottom] = useState(() => windowSize.width < 1600 ? 70 : 105)

  const handleNavigation = (key: string) => {
    setCurrentKey(key);
  };


  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      if (window.innerWidth < 1500) {
        setRight(100)
        setBottom(70)
      } else {
        setRight(90)
        setBottom(105)
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (

    <div id="menu_container">
      <div className="mobileMenu mobile-radio-input" id='menu' >
        {Object.keys(menu).map((listKey, index) => (
          <div className='mobileMenuIteam'
            onClick={() => handleNavigation(listKey)}>
            {currentKey === listKey ?
              <img className="mobileMenuIcon" src={menuActiveIcons[listKey]} alt="" /> :
              <img className="mobileMenuIcon" src={menuIcons[listKey]} alt="" />
            }
            <label
              key={`value-` + index + 1}
            >
              <input
                value={`value-` + index + 1}
                name="value-radio"
                id={`value-` + index + 1}
                type="radio"
                checked={listKey === currentKey}
                defaultChecked={index === 0}
              />
              <span>{menu[listKey]}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}