import './App.css'
import { Header } from './components/header/Header'
import { Home } from './components/home/Home'
import { Packages } from './components/packages/Packages';
import { Extras } from './components/extras/Extras';
import { Photos } from './components/photos/Photos';
import { ContactUs } from './components/contactUs/ContactUs';
import { LeftBar } from './components/leftBar/LeftBar';
import { RightBar } from './components/rightBar/RightBar';
import { useEffect, useState } from 'react';
import texts from './assets/en.json';
import useWindowDimensions from './shared/useDimensions';
import { MobileHeader } from './mobile/header/Header';
import { MobileMenu } from './mobile/Menu/Menu';
import { MobileFooter } from './mobile/footer/Footer';
import { MobileHome } from './mobile/main/home/Home';
import { MobilePackages } from './mobile/main/packages/Packages';
import Modal from './mobile/modal/Modal';
import { MobileExtras } from './mobile/main/extras/Extras';
import { MobilePhotos } from './mobile/main/photos/Photos';
import { MobileContactUs } from './mobile/contactUs/ContactUs';
import PackageOne from './mobile/main/packages/PackageOne';
import PackageTwo from './mobile/main/packages/PackageTwo';
import PackageThree from './mobile/main/packages/PackageThree';


interface PackageInfoItem {
  title: string,
  price: string,
  component: JSX.Element,
  id: number
}

interface PackagesInfo extends Array<PackageInfoItem>{}

function App() {
  const header: any = texts.header;

  const packagesInfo: PackagesInfo = [
    {
      title: 'Party Package One',
      price: '$1700',
      component: <PackageOne />,
      id: 1
    },
    {
      title: 'Party Package Two',
      price: '$2200',
      component: <PackageTwo />,
      id: 2

    },
    {
      title: 'Party Package Three',
      price: '$2800',
      component: <PackageThree />,
      id: 3

    },
  ]

  const [currentKey, setCurrentKey] = useState('home');
  const [previousKey] = useState(currentKey);
  const [toOpacify, setToOpacify] = useState(false);
  const [contactUs] = useState(header.contactUs.replace(' ', '').toLowerCase());
  const [backgroundPosition, setBackgroundPosition] = useState<string>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { width } = useWindowDimensions();
  const [renderElement, setRenderElement] = useState()
  const [vector6Position, setVector6Position] = useState('')
  const [vector7Position, setVector7Position] = useState('')
  const [vector8Position, setVector8Position] = useState('')
  const [vector9Position, setVector9Position] = useState('')
  const [packagesIndex, setPackagesIndex] = useState(1)
  const [activePackageIndex, setActivePackageIndex] = useState(null)

  useEffect(() => {
    if (currentKey === header.home.toLowerCase()) {
      setVector6Position('10% 80%')
      setVector7Position('70% 95%')
      setVector8Position('72% 40%')
      setVector9Position('90% 70%')
    } else if (currentKey === header['party packages'].toLowerCase() || currentKey === header.photos.toLowerCase()) {
      setVector6Position('5% 80%')
      setVector7Position('70% 90%')
      setVector8Position('65% 35%')
      setVector9Position('95% 70%')
    } else if (currentKey === header.extras.toLowerCase()) {
      setVector6Position('10% 70%')
      setVector7Position('75% 85%')
      setVector8Position('68% 38%')
      setVector9Position('90% 80%')
    } else {
      setVector6Position('25% 60%')
      setVector7Position('50% 85%')
      setVector8Position('73% 45%')
    }

    if (currentKey === header.photos.toLowerCase()) {
      setVector8Position('70% 42%')
      setVector9Position('98% 30%')
    }
  }, [currentKey])

  useEffect(() => {
    let result = 'center'
    if (currentKey.toLowerCase() === contactUs) {
      result = '90% 25%'
    }
    setBackgroundPosition(result)
  }, [currentKey])

  useEffect(() => {
    if (currentKey === header['party packages'].toLowerCase() ||
      currentKey === header.extras.toLowerCase() ||
      currentKey === header.photos.toLowerCase()
    ) {
      setToOpacify(true)
    } else {
      setToOpacify(false)
    }
  })


  const findActivePackage = (id) => {
    return packagesInfo.find(item => item.id === id)
  }
  const openModal = (index: number) => {
    setActivePackageIndex(index)
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  // const changeExtrasImage = () => {
  //   setRotation((prevRotation) => (prevRotation + 180) % 360);
  //   extrasImageIndex == 1 ? setExtrasImageIndex(2) : setExtrasImageIndex(1)
  // }

  return (
    <div className="App">

      {!(width >= 0 && width <= 800) ?
        (
          <>
            <div className='Container'>
              <div className='containerName' style={
                {
                  opacity: toOpacify ? 0.1 : 1,
                  backgroundPosition,
                }
              }></div>
              <div className='vector6' style={
                {
                  backgroundPosition: vector6Position
                }
              }></div>
              <div className='vector7' style={
                {
                  backgroundPosition: vector7Position
                }
              }></div>
              <div className='vector8' style={
                {
                  backgroundPosition: vector8Position
                }
              }></div>
              <div className='vector9' style={
                {
                  backgroundPosition: vector9Position
                }
              }></div>
              <Header setCurrentKey={setCurrentKey} currentKey={currentKey} />
              
              <LeftBar currentKey={currentKey} contactUsKey={contactUs} />
              <RightBar setCurrentKey={setCurrentKey} currentKey={currentKey} />
              <Home currentKey={currentKey} />
              <Packages currentKey={currentKey} previousKey={previousKey} />
              <Extras currentKey={currentKey} />
              <Photos currentKey={currentKey} />
              {/* <Rules currentKey={currentKey} /> */}
              <ContactUs currentKey={currentKey} contactUsKey={contactUs} />
            </div>
          </>
        )
        :
        <div className='mobile'>
          <div className='mobileContainerName' style={
            {
              opacity: toOpacify ? 0.1 : 1,
              backgroundPosition,
            }
          }></div>
          <div className='mobileVector6' style={
            { opacity: toOpacify ? 0.5 : 1 }
          }></div>
          <div className='mobileVector7' style={
            { opacity: toOpacify ? 0.5 : 1 }
          }></div>
          <div className='mobileVector8' style={
            { opacity: toOpacify ? 0.5 : 1 }
          }></div>
          <div className='mobileVector9' style={
            { opacity: toOpacify ? 0.5 : 1 }
          }></div>
          <div className='mobileContainer'>

            <MobileHeader setCurrentKey={setCurrentKey} isContactUs={currentKey.toLowerCase() === contactUs} />
            <div className='mobileMain'>
              <div className='mobileMain-Main'>
                <MobileHome currentKey={currentKey} />
                <MobilePackages currentKey={currentKey} previousKey={previousKey} renderInfo={packagesInfo} openModal={openModal} setRenderElement={setRenderElement} />
                <MobileExtras currentKey={currentKey} previousKey={''} setRenderElement={setRenderElement} />
                <MobilePhotos currentKey={currentKey} />
                {/* <MobileRules currentKey={currentKey} /> */}
                <MobileContactUs currentKey={currentKey} contactUsKey={contactUs} />
              </div>
              <div className='mobileMain-Footer'>
                <MobileFooter setCurrentKey={setCurrentKey} currentKey={currentKey} />
              </div>
            </div>
            <MobileMenu setCurrentKey={setCurrentKey} currentKey={currentKey} />
          </div>

          <Modal isVisible={isModalVisible} onClose={closeModal} renderElement={findActivePackage(activePackageIndex)?.component} packagesIndex={packagesIndex} />
        </div>}
    </div >
  );
}

export default App;
