import { useEffect, useState } from 'react';
import './Home.css';
import { useTransition, animated } from 'react-spring';
import texts from '../../assets/en.json';

interface IHome {
  currentKey: string
}

export const Home = ({ currentKey }: IHome) => {
  const header: any = texts.header;
  const home: any = texts.home;
  const [isVisible, setIsVisible] = useState(true)
  const [windowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [bottom, setBottom] = useState(windowSize.width < 1600 ? 20 : 80)

  const transaction = useTransition(isVisible, {
    from: { bottom: -100 },
    enter: { bottom: 0 },
    leave: { bottom: -100 },
    config: { duration: 500, easing: t => t * t }
  })
  useEffect(() => {
    if (currentKey !== header.home.toLowerCase()) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [currentKey])

  useEffect(() => {
    if (windowSize.width < 1600) {
      setBottom(20)
    } else {
      setBottom(80)
    }
  }, [windowSize.width])

  return (<>
    {transaction((style, item) => item ? (
      <animated.div style={{ ...style, bottom: `${bottom}px !important` }} className='container item' id='home'>
        <div>
          <span>{home.title}</span>
        </div>
        <div>
          <span>{home.description}</span>
        </div>

        <div className='home_top-right'> 
          <span>
            Admission fee: $25 per child
          </span>
          <span>
            Operating hours for open play: 10am-8pm <br /> (hours may vary if there is a private event)
          </span>
          <span>
            Open 7 days a week
            <br />
            (unless booked for a private event)
          </span>
        </div>
      </animated.div>
    ) : '')}
  </>)
}
