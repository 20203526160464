import { useEffect, useState } from 'react';
import texts from '../../assets/en.json';
import { useTransition, animated } from 'react-spring';
import phone from '../../assets/images/phone.png'
import email from '../../assets/images/email.png'
import pin from '../../assets/images/pin.png'
import './ContactUs.css'
import yell from '../../assets/images/yell.png'
import instagram from '../../assets/images/instagram.png'

interface IContactUs {
  currentKey: string
  contactUsKey: string
}

export const ContactUs = ({ currentKey, contactUsKey }: IContactUs) => {
  const header: any = texts.header;
  const contactUs: any = texts.contactUs;

  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    if (currentKey.toLowerCase() !== contactUsKey) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [currentKey])

  const transaction = useTransition(isVisible, {
    from: { transform: 'translateY(200%)' },
    enter: { transform: `translateY(-15%)` },
    leave: {
      transform: `translateY(200%)`
    },
    config: { duration: 500, easing: t => t * t }
  })

  return (<>
    {transaction((style, item) => item ? (
      <animated.div style={{ ...style }} className='container item' id='contactUs'>
        <h1>{contactUs.title}</h1>
        {/* <h3>{contactUs.subtitle}</h3> */}
        <p>{contactUs.description}</p>
        <div className='contact-us-container'>
          <div id='yell'>
            <img src={yell} alt="" style={{ top: currentKey.toLowerCase() === contactUsKey.toLowerCase() ? '0' : '-30px' }} />
            {currentKey.toLowerCase() === contactUsKey ? <a target='_blank' href='https://yelp.to/OAVYJ06Jed'>{contactUs.yell}</a> : <></>}
          </div>
          <div id='instagram'>
            <img src={instagram} alt="" style={{ top: currentKey.toLowerCase() === contactUsKey.toLowerCase() ? '0' : '-30px' }} />
            {currentKey.toLowerCase() === contactUsKey ? <a target='_blank' href='https://instagram.com/kidztopia_indoorplayground?igshid=OGQ5ZDc2ODk2ZA=='>{contactUs.instagram}</a> : <></>}
          </div>
          <div id='phone'>
            <img src={phone} alt="" />
            <a href={`tel:${contactUs.phone}`}>{contactUs.phone}</a>
          </div>
          <div id='email'>
            <img src={email} alt="" />
            <a href={`mailto:${contactUs.email}`}>{contactUs.email}</a>
          </div>
          <div id='address'>
            <img src={pin} alt="" />
            <a>{contactUs.address}</a>
          </div>
        </div>
      </animated.div>
    ) : ''
    )}
  </>
  );
}