import { useEffect, useState } from 'react';
import texts from '../../../assets/en.json';
import { useTransition, animated } from 'react-spring';
import ButtonPointer from '../../../assets/images/buttonPointer.svg'
import './Packages.css'

interface MobilePackages {
  currentKey: string
  previousKey: string,
  openModal: void
  setRenderElement: any,
  renderInfo: object[]
}

export const MobilePackages = ({ currentKey, setRenderElement, openModal, renderInfo }: MobilePackages) => {

  const [isVisible, setIsVisible] = useState(false);
  const header: any = texts.header;
  const packages: any = texts.packages;


  

  const transaction = useTransition(isVisible, {
    from: { transform: 'translateY(100%)', position: 'absolute', width: '95%' },
    enter: { transform: 'translateY(0%)', position: 'relative', width: '95%' },
    leave: { transform: 'translateY(-100%)', position: 'absolute', width: '95%' },
    config: { duration: 500 }
  })


  useEffect(() => {
    
    if (currentKey !== header['party packages'].toLowerCase()) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [currentKey, header['party packages']])

  useEffect(() => {
    const elements = [];
    for (let index = 0; index < 6; index++) {
      elements.push(
        <div className='content-item'>
          <span className='part-time'>{packages.partTime}</span>
          <span className='hours'>{packages.hours}</span>
        </div>
      )
    }
    setRenderElement(elements)

  }, [])


  return (<>
    {transaction((style, item) => item ? (
      <animated.div
        style={style}
        className='packagesContainer' id='mobilePackages' >


        {renderInfo.map((item, i) => (
          <div key={i} className='mobile-section' onClick={() => {openModal(item.id)}}>
            <div className='mobile-section-sub' >
              <p className='mobile-title'>{item.title}</p>
              <p className='mobile-price'>{item.price}</p>
              <p className='mobile-service'>+(15% Service Fee)+(9.5% Tax)</p>
            </div>
            <div className='mobile-buttonPointer' >
              <button className='buttonPointer'>
                <img src={ButtonPointer} alt="" />
              </button>
            </div>
          </div>
        ))}

      </animated.div>
    ) : '')}
  </>)
}