import { useEffect, useState } from 'react';
import texts from '../../assets/en.json';
import { useTransition, animated } from 'react-spring';
import './Extras.css';

interface IExtras {
	currentKey: string;
}

export const Extras = ({ currentKey }: IExtras) => {
	const header: any = texts.header;
	const [isVisible, setIsVisible] = useState(false);


	const addOnList = [
		{
			name: 'Goodie bags:',
			price: '$8 (each)',
		},
		{
			name: 'One piece Goodie bag display:',
			price: '$150',
		},
		{
			name: 'Two piece goodie bag display:',
			price: '$250',
		},
		{
			name: 'Three piece goodie bag display:',
			price: '$350',
		},
		{
			name: 'Character one hour:',
			price: '$300',
		},
		{
			name: 'Character two hour:',
			price: '$550',
		},
		{
			name: 'Large pepperoni pizza:',
			price: '$23',
		},
		{
			name: 'Large cheese pizza:',
			price: '$18',
		},
		{
			name: 'Large veggie pizza:',
			price: '$27',
		},
		{
			name: 'Face painting for 2 hours:',
			price: '$300',
		},
		{
			name: 'Armenian Coffee:',
			price: '$3 per guest',
		},
		{
			name: 'American Coffee:',
			price: '$2 per guest',
		},
		{
			name: 'Tea:',
			price: '$2 per guest',
		},
	]

	const transaction = useTransition(isVisible, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: { duration: 500 }
	})

	useEffect(() => {
		if (currentKey !== header.extras.toLowerCase()) {
			setIsVisible(false)
		} else {
			setIsVisible(true)
		}
	}, [currentKey, header.extras])

	return (
		<>
			{transaction((style, item) => item ? (
				<animated.div style={style} className='container item' id='extras'>
					<div className='extras__wrapper'>
						<div className='extras__column'>
							<div className='extras__item' id='rent_birthday'>
								<h2 className='extras__item_heading'>Rent out location for birthday celebration</h2>
								<p className='extras__item_price'>$700/hour</p>
								<p className='extras__item_tax'>( Additional 15% service fee 9.5% tax ) <br /> 2 hour minimum celebration</p>
								<div className='extras__item_info'>
									<div className='extras__item_infoblock'>
										<p className='extras__item_infoname'>Kids</p>
										<b className='extras__item_infocount'>30</b>
									</div>
									<div className='extras__item_infoblock'>
										<p className='extras__item_infoname'>Adults</p>
										<b className='extras__item_infocount'>30</b>
									</div>
								</div>

								<div className='extras__item_bottom'>
									<p>For each additional hour</p>
									<b>$700</b>
								</div>
							</div>

							<div className='extras__item' id='add_on'>
								<h2 className='extras__item_heading'>Add on for party packages</h2>
								<div className='add_on_info'>

									{addOnList.map((item, i) => (
										<div key={i} className='add_on_block'>
											<b className='add_on_name'>{item.name}</b>
											<p className='add_on_price'>{item.price}</p>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className='extras__column'>

							<div className='extras__item' id='rent_location'>
								<h2 className='extras__item_heading'>Rent out location</h2>
								<p className='extras__item_price'>$850/hour</p>
								<p className='extras__item_tax'>( Additional 15% service fee 9.5% tax ) For use of private play , Commercials, Photo Shoots, Movies </p>
							</div>

							<div className='extras__item' id='tips'>
								<h2 className='extras__item_heading'>Tips for your celebration</h2>

								<span className='tips__text'>
									30 day notice is needed to cancel or reschedule date of event <br />
									All events require a 15% service fee. This charge is to help cover the time setting up ,cleaning, prepping, planning during and after your event. This is not a tip. Tip is separate and will be given to the staff that is working the day of your event.<br />
									A 40% non refundable deposit is required at the time of booking <br />
									Remaining balance should be fully paid day of the event <br />
									State sales of 9.5% will be added to any service we provide <br />
									In order for us to sanitize and prepare for next event to there will be a 20 minute grace period for all guests to leave after party is over. If passed this time you wil be charged $200.
								</span>
							</div>
						</div>

						<div className='extras__column'>
							<div className='extras__item' id='rent_private'>
								<h2 className='extras__item_heading'>None private events during open play</h2>
								<p className='extras__item_price'>$700/2 hours</p>
								<p className='extras__item_tax'>( Additional 15% service fee 9.5% tax ) <br /> 2 hour minimum celebration</p>
								<div className='extras__item_info'>
									<div className='extras__item_infoblock'>
										<p className='extras__item_infoname'>Kids</p>
										<b className='extras__item_infocount'>10</b>
									</div>
									<div className='extras__item_infoblock'>
										<p className='extras__item_infoname'>Adults</p>
										<b className='extras__item_infocount'>20</b>
									</div>
									<div className='extras__item_infoblock'>
										<p className='extras__item_infoname'>One topping large pizza </p>
										<b className='extras__item_infocount'>2</b>
									</div>
									<div className='extras__item_infoblock'>
										<p className='extras__item_infoname'>Organic juice boxes for kids</p>
										<b className='extras__item_infocount'>10</b>
									</div>
								</div>

								<div className='extras__item_bottom'>
									<p>For each additional hour</p>
									<b>$350</b>
								</div>
							</div>
							<div className='extras__item' id='pre_tips'>
								<h2 className='extras__item_heading'>Pre arrival tips</h2>

								<span className='pretips__text'>
									Sign wavier to prevent wait time <br /> 
									<br /> 

									Admission fee: $25 per child<br />
									Adults are free <br /> <br /> 

									Each child is allowed 2 adults <br />
									Additional adults will be charged $15 <br /><br />

									Operating hours for open play : 10am-8pm (hours may vary if there is a private event)
									Open 7 days a week (unless booked for a private event) <br /><br />

									No time limit for free play on regular days
									2 hour time limit for free play on  holidays <br /><br />

									No food or drink inside play area <br />
									No dirty socks allowed <br />
									Children must be supervised by parent at all times <br />
									Children who are not potty trained or are in training must wear diapers at all times during play as we want to keep the area as sanitary as possible <br />
									We allow you to bring your choice of food and drinks inside sitting area
								</span>
							</div>
						</div>
					</div>
				</animated.div>
			) : '')}
		</>
	);
}