import './Home.css';
import { useEffect, useState } from 'react';
import { useTransition, animated } from 'react-spring';
import texts from '../../../assets/en.json';
import kidztopia from '../../../assets/images/kidztopia.png'
import yell from '../../../assets/images/yell.png'
import instagram from '../../../assets/images/instagram.png'

interface IHome {
  currentKey: string
}

export const MobileHome = ({ currentKey }: IHome) => {
  const header: any = texts.header;
  const home: any = texts.mobileHome;
  const [isVisible, setIsVisible] = useState(true)

  const transaction = useTransition(isVisible, {
    from: { transform: 'translateY(100%)', position: 'absolute' },
    enter: { transform: 'translateY(0%)', position: 'relative' },
    leave: { transform: 'translateY(-100%)', position: 'absolute' },
    config: { duration: 500 }
  })

  useEffect(() => {
    if (currentKey !== header.home.toLowerCase()) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [currentKey])

  return (<>
    {transaction((style, item) => item ? (
      <animated.div style={{ ...style }} id='mobile_home'>
        <div className='homeTitle' id='mobileHomeText' >
          <div>
            <p>{home.title}</p>
            <p>{home.description1}</p>
            <p>{home.description2}</p>
          </div>
          
          <div id='mobileInstagram'>
            <a target="_blank" href="https://instagram.com/kidztopia_indoorplayground?igshid=OGQ5ZDc2ODk2ZA=="><img src={instagram} alt="" /></a>
          </div>
          <div id='mobileYell'>
            <a target="_blank" href="https://yelp.to/OAVYJ06Jed"><img src={yell} alt="" /></a>
          </div>
        </div>
        <div className='mobileHomeImg' id='mobileHomeImg'>
          <img className='homeImage' src={kidztopia} alt="" />
        </div>
        <div className='mobilehome_add'>
          <p>Admission fee: $25 per child</p>
          <p>Operating hours for open play: 10am-8pm <br /> (hours may vary if there is a private event)</p>
          <p>Open 7 days a week
            <br />
            (unless booked for a private event)</p>
        </div>
      </animated.div>
    ) : '')}
  </>)
}
