import { useEffect, useState } from 'react';
import texts from '../../assets/en.json';
import { useTransition, animated } from 'react-spring';
import phone from '../../assets/images/phone.png'
import email from '../../assets/images/email.png'
import pin from '../../assets/images/pin.png'
import kidztopia from '../../assets/images/kidztopia.png'
import yell from '../../assets/images/yell.png'
import instagram from '../../assets/images/instagram.png'
import './ContactUs.css'

interface MContactUs {
  currentKey: string
  contactUsKey: string
}

export const MobileContactUs = ({ currentKey, contactUsKey }: MContactUs) => {
  const header: any = texts.header;
  const contactUs: any = texts.contactUs;

  const [isVisible, setIsVisible] = useState(true)

  const transaction = useTransition(isVisible, {
    from: { transform: 'translateY(100%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(-100%)' },
    config: { duration: 500 }
  })
  useEffect(() => {
    if (currentKey.toLowerCase() !== contactUsKey) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [currentKey])


  return (<>
    {transaction((style, item) => item ? (
      <animated.div style={{ ...style }} className='container item' id='mobile_contactUs'>
        <div id='contactUsTitle'>
          <h1>{contactUs.title}</h1>
          {/* <h3>{contactUs.subtitle}</h3> */}
          <p>{contactUs.description}</p>
          <div id='kidztopia'>
          <img src={kidztopia} alt="" />
        </div>
        </div>

        <div className='contact-us-container'>
          <div id='mobile_yell'>
            <img src={yell} alt="" style={{ top: currentKey.toLowerCase() === contactUsKey.toLowerCase() ? '0' : '-30px' }} />
            {currentKey.toLowerCase() === contactUsKey ? <a target='_blank' href='https://yelp.to/OAVYJ06Jed'>{contactUs.yell}</a> : <></>}
          </div>
          <div id='mobile_instagram'>
            <img src={instagram} alt="" style={{ top: currentKey.toLowerCase() === contactUsKey.toLowerCase() ? '0' : '-30px' }} />
            {currentKey.toLowerCase() === contactUsKey ? <a target='_blank' href='https://instagram.com/kidztopia_indoorplayground?igshid=OGQ5ZDc2ODk2ZA=='>{contactUs.instagram}</a> : <></>}
          </div>
          <div id='mobile_phone'>
            <img src={phone} alt="" />
            <a href={`tel:${contactUs.phone}`}>{contactUs.phone}</a>
          </div>
          <div id='mobile_email'>
            <img src={email} alt="" />
            <a href={`mailto:${contactUs.email}`}>{contactUs.email}</a>
          </div>
          <div id='mobile_address'>
            <img src={pin} alt="" />
            <a>{contactUs.address}</a>
          </div>
        </div>
      </animated.div>
    ) : '')}
  </>
  );
}