const PackageOne = () => {
  return (
    <div className='mobile_packages__item '>
      <h2 className='mobile_package__heading'>Party Package One</h2>
      <p className='mobile_package__price'>$1700</p>
      <p className='mobile_package__tax'>( Additional 15% service fee 9.5% tax )</p>

      <div className='mobile_package__infos'>
        <div className='mobile_package__info'>
          <p className='mobile_package__infoname'>Party Time</p>
          <b className='mobile_package__boldinfo'>2 Hours</b>
        </div>
        <div className='mobile_package__info'>
          <p className='mobile_package__infoname'>Kids</p>

          <div>
            <span className='mobile_package__addinfo'>(each additional kid $16)</span>
            <b className='mobile_package__boldinfo'>up to 22</b>
          </div>
        </div>
        <div className='mobile_package__info'>
          <p className='mobile_package__infoname'>Adults</p>

          <div>
            <span className='mobile_package__addinfo'>(additional adult $12)</span>
            <b className='mobile_package__boldinfo'>up to 32</b>
          </div>
        </div>
        <div className='mobile_package__info'>
          <p className='mobile_package__infoname'>Helpers</p>
          <b className='mobile_package__boldinfo'>2-3</b>
        </div>
        <div className='mobile_package__info'>
          <p className='mobile_package__infoname'>Large 1 topping pizza</p>
          <b className='mobile_package__boldinfo'>4</b>
        </div>
        <div className='mobile_package__info'>
          <p className='mobile_package__infoname'>Organic Juice boxes kids</p>
          <b className='mobile_package__boldinfo'>22</b>
        </div>
        <div className='mobile_package__info'>
          <p className='mobile_package__infoname'>Mini Water bottles</p>
          <b className='mobile_package__boldinfo'>provided for all guests</b>
        </div>
        <div className='mobile_package__info'>
          <p className='mobile_package__infoname'>Non slip socks</p>
          <b className='mobile_package__boldinfo'>provided for all guests</b>
        </div>
        <p className='mobile_package__secondinfo'>American coffee with creamer and sugar</p>
        <p className='mobile_package__secondinfo'>Plates, napkins, flatwear for adults </p>
        <p className='mobile_package__secondinfo'>Cake celebration, cutting ,serving cake</p>
        <p className='mobile_package__secondinfo last'>Cake stands included (options of clear, white, variety of colors/ designs to chose from) </p>
      </div>

      <div className='mobile_package__additional'>
        <div className='mobile_package__additional_info'>
          <p>Additional 30 minutes</p>
          <b>$250</b>
        </div>
        <div className='mobile_package__additional_info'>
          <p>Additional 1 hour</p>
          <b>$350</b>
        </div>
      </div>
    </div>
  )
}

export default PackageOne